import { get, isEmpty } from 'lodash-es'

import AlertContent from '@/Components/alerts/AlertContent'
import CurrentDateTime from '@/Components/common/CurrentDateAndTime'

export default function BottomNotification(props) {
  const {
    setDisabled,
    showTimeWarning,
    siteTime,
    setCurrentTime,
  } = props

  return (
    <>
      {(!isEmpty(siteTime) && !setDisabled) && (
        <div>
          <AlertContent
            title={showTimeWarning ? 'Program Start Imminent' : null}
            type={showTimeWarning ? 'warning' : 'info'}
            hideIcon={false}
            className="mt-5"
          >
            <p>
              {showTimeWarning && (
                <span className="mr-1.5">
                  When you save this program set, it will be scheduled to run immediately.
                </span>
              )}

              <span className="mr-1.5">
                The current time at the site is
              </span>

              <CurrentDateTime
                initialTime={get(siteTime, 'siteTime.utc')}
                timezone={get(siteTime, 'timeZone')}
                showAbbreviation={false}
                setTime={setCurrentTime}
              />
            </p>
          </AlertContent>
        </div>
      )}

      {setDisabled && (
        <div>
          <AlertContent
            title={'Program set disabled'}
            type={'warning'}
            hideIcon={false}
            className="mt-5"
          >
            <p>
              This program set and associated programs will not run.
            </p>
          </AlertContent>
        </div>
      )}
    </>
  )
}
