import '@/Utilities/Form/Yup'

import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import classNames from 'classnames'
import { get, isEmpty, isFunction, map, padStart, toLower } from 'lodash-es'
import moment from 'moment-timezone'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useRecoilState, useSetRecoilState } from 'recoil'
import styled from 'styled-components'

import AlertContent from '@/Components/alerts/AlertContent'
import { Anchor, Button } from '@/Components/form/Buttons'
import Modal from '@/Components/Modal'
import BottomNotifications from '@/Components/modals/programSet/BottomNotifications'
import General from '@/Components/modals/programSet/General'
import Timing from '@/Components/modals/programSet/Timing'
import Tabs from '@/Components/Tabs'
import { isLoadingState, modalState, pageAlertState } from '@/Config/Atoms/General'
import { getSiteOptions } from '@/Utilities/Accessors/Sites'
import { publish } from '@/Utilities/Events'
import { defaultValue, flattenSelectValues, formatKeys } from '@/Utilities/Form/Formatter'
import useApiClient from '@/Utilities/useApiClient'
import useEntityMonitor from '@/Utilities/useEntityMonitor'
import useFormRevalidate from '@/Utilities/useFormRevalidate'
import usePendingChange from '@/Utilities/usePendingChange'
import SetSchema from '@/Utilities/Validation/SetSchema'

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`

const ButtonGroup = styled.div`
  display: flex;
  width: calc(50% - 5px);
`

const Form = styled.form`
  label {
    margin-top: 20px;
  }

  .buttons {
    margin-top: 40px;
  }
`

export default function ProgramSetModal(props) {
  const apiClient = useApiClient()
  const [selectedTab, setSelectedTab] = useState('general')
  const [setupLoading, setSetupLoading] = useState(true)
  const [siteOptions, setSiteOptions] = useState([])
  const [siteTime, setSiteTime] = useState({})
  const [tabErrors, setTabErrors] = useState({})
  const [isLoading, setIsLoading] = useRecoilState(isLoadingState)
  const [currentTime, setCurrentTime] = useState(null)
  const firstLoad = useRef(true)
  const form = useRef(null)
  const setModal = useSetRecoilState(modalState)
  const setAlert = useSetRecoilState(pageAlertState)
  const {
    isInRunningState,
    getProgramDisabledReason,
  } = useEntityMonitor()

  const {
    hasPendingChange,
    getPendingChangeDisabledReason,
  } = usePendingChange()

  const isEditing = props.data?.isEditing || false

  const programSet = useMemo(() => {
    let programSet = get(props, 'data.programSet', null)

    if (programSet) {
      programSet = formatKeys(props.data.programSet, 'camel')
    }

    return programSet
  }, [props.data?.programSet])

  const preventSaving = isInRunningState('programSet', programSet?.id) || hasPendingChange(programSet)
  const runningProgramSetDisableReason = getProgramDisabledReason('programSet', programSet?.id)
  const pendingChangeDisableReason = getPendingChangeDisabledReason(programSet)

  const defaultSiteValue = useMemo(() => {
    return programSet && programSet.site ? defaultValue(programSet, 'site') : defaultValue(props?.data, 'site')
  }, [programSet, props?.data])

  const {
    trigger,
    reset,
    watch,
    setValue,
    getValues,
    control,
    register,
    handleSubmit,
    formState,
  } = useForm({
    resolver: yupResolver(SetSchema),
    defaultValues: { siteId: defaultSiteValue },
  })

  // Perform form revalidation when dirty and any field is updated
  const { submitErrorHandler } = useFormRevalidate({
    watch,
    formState,
    trigger,
    schema: SetSchema,
    revalidateWhen: formState.isDirty && formState.isSubmitted,
    setSelectedTab,
  })

  const {
    errors,
    dirtyFields,
  } = formState

  const schedule = watch('schedule')
  const startTimeHours = watch('startTimeHours')
  const startTimeMinutes = watch('startTimeMinutes')
  const stopTimeHours = watch('stopTimeHours')
  const stopTimeMinutes = watch('stopTimeMinutes')
  const setDisabled = watch('disabled')

  const tabs = [{
    title: 'General',
    key: 'general',
    component: General,
    hasError: tabErrors['general'],
  }, {
    title: 'Timing',
    key: 'timing',
    component: Timing,
    hasError: tabErrors['timing'],
  }]

  const scheduleTypeOptions = useMemo(() => {
    return [{
      value: 'days',
      label: 'Days of week',
    }]

    // Add to the array above to enable day intervals
    // {
    //   value: 'interval',
    //   label: 'Days interval',
    // }
  }, [])

  useEffect(() => {
    if (programSet && firstLoad.current) {
      firstLoad.current = false

      // Manually register operational days schedule
      register('schedule', { value: programSet.schedule })
      setValue('schedule', programSet.schedule)
    }
  }, [
    reset,
    setValue,
    register,
    programSet,
    schedule,
  ])

  useEffect(() => {
    (async () => {
      setSiteOptions(await getSiteOptions(apiClient))
      setSetupLoading(false)
    })()
  }, [])

  const showTimeWarning = useMemo(() => {
    if (currentTime && !isEmpty(siteTime)) {
      const timeNow = moment.tz(siteTime.timeZone)
      const dateNow = timeNow.format('YYYY-MM-DD')
      const isoWeek = timeNow.isoWeek()
      const currentDay = toLower(timeNow.format('dddd'))
      const currentWeek = (isoWeek - 1) % 4

      // If today is not selected in the schedule, return false
      if (!get(schedule, `${currentWeek}.${currentDay}`, false)) {
        return false
      }

      const paddedStartTime = `${padStart(startTimeHours, 2, 0)}:${padStart(startTimeMinutes, 2, 0)}`
      const paddedStopTime = `${stopTimeHours ? padStart(stopTimeHours, 2, 0) : '23'}:${stopTimeMinutes ? padStart(stopTimeMinutes, 2, 0) : '59'}`
      const startTime = moment.tz(`${dateNow} ${paddedStartTime}`, siteTime.timeZone)
      const stopTime = moment.tz(`${dateNow} ${paddedStopTime}`, siteTime.timeZone)

      // If the current time is after the stop time and the start time is after the stop time, return true
      if (stopTime.isSameOrBefore(startTime) && timeNow.isSameOrAfter(startTime)) {
        return true
      }

      // If the current time is between the start and stop time, return true
      return timeNow.isBetween(startTime, stopTime, 'minute', '[]')
    }

    // If the current time is not available, return false
    return false
  }, [
    currentTime,
    siteTime,
    startTimeHours,
    startTimeMinutes,
    stopTimeHours,
    stopTimeMinutes,
    schedule,
  ])

  const tabChange = useCallback((value) => {
    setSelectedTab(value)
  }, [setSelectedTab])

  const onSubmit = async (data, afterSave = null) => {
    data = flattenSelectValues(data)

    data.startTime = `${padStart(data.startTimeHours, 2, 0)}:${padStart(data.startTimeMinutes, 2, 0)}:00`
    data.stopTime = `${padStart(data.stopTimeHours, 2, 0)}:${padStart(data.stopTimeMinutes, 2, 0)}:00`
    data.intervalBetweenCycles = null

    if (data.intervalBetweenCyclesHours || data.intervalBetweenCyclesMinutes || data.intervalBetweenCyclesSeconds) {
      data.intervalBetweenCycles = `${padStart(data.intervalBetweenCyclesHours, 2, 0)}:${padStart(data.intervalBetweenCyclesMinutes, 2, 0)}:${padStart(data.intervalBetweenCyclesSeconds, 2, 0)}`
    }

    data = formatKeys(data, 'snake')

    setAlert(null)
    setIsLoading(true)

    try {
      let { data: responseData } = await apiClient.post(`/program-set/${isEditing ? `update/${props.data.programSet.id}`: 'create'} `, data)

      if (isFunction(afterSave)) {
        await afterSave()
      }

      if (responseData.success) {
        if (isFunction(props.data?.onSave)) {
          props.data.onSave()
        }

        setAlert({
          type: 'info',
          content: `Attempting to ${isEditing ? 'update' : 'create'} Program Set ${responseData.programSet.name}.`,
        })

        publish('programCreated')
        props.close()

        if (!isEditing) {
          setModal({
            name: 'program',
            data: { program: { programSetId: responseData.programSet.id } },
          })
        }
      }
    } catch (error) {
      setAlert({
        type: 'error',
        content: 'An error has occured and we were not able to save this program set. Please try again.',
      })
      Sentry.captureException(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      title={props.data?.site ? `${isEditing ? 'Edit': 'Add'} a program set for ${props.data?.site.name}` : `${isEditing ? 'Edit': 'Add'} program set`}
      close={props.close}
      closeOnOutsideClick={props.closeOnOutsideClick}
    >
      {setupLoading ?
        <div className="flex h-80 items-center justify-center">
          <div className="primary-loader"></div>
        </div> : (
          <>
            {preventSaving && (
              <AlertContent className="mb-4">
                {runningProgramSetDisableReason || pendingChangeDisableReason}
              </AlertContent>
            )}

            <Tabs
              currentlySelected={selectedTab}
              defaultTab="general"
              tabs={tabs}
              onChange={tabChange}
            />
            <FormProvider
              control={control}
              dirtyFields={dirtyFields}
              errors={errors}
              getValues={getValues}
              handleSubmit={handleSubmit}
              register={register}
              reset={reset}
              setValue={setValue}
              trigger={trigger}
              watch={watch}
            >
              <Form onSubmit={handleSubmit(onSubmit, submitErrorHandler)} autoComplete="off" ref={form}>
                {map(tabs, (tab) => {
                  return (
                    <div className={classNames({ hidden: tab.key != selectedTab })} key={tab.key} id={tab.key}>
                      <tab.component
                        {...props}
                        setSiteTime={setSiteTime}
                        scheduleTypeOptions={scheduleTypeOptions}
                        siteOptions={siteOptions}
                        tabErrors={tabErrors}
                        setTabErrors={setTabErrors}
                        setDisabled={setDisabled}
                      />
                    </div>
                  )
                })}

                <BottomNotifications
                  setDisabled={setDisabled}
                  showTimeWarning={showTimeWarning}
                  siteTime={siteTime}
                  setCurrentTime={setCurrentTime}
                />

                <ButtonWrapper className="buttons">
                  <Anchor
                    style={{ width: 'calc(50% - 5px)' }}
                    className="transparent"
                    onClick={() => {
                      setAlert(null)
                      reset()
                      props.close()
                    }}
                  >
                    Close
                  </Anchor>

                  <ButtonGroup>
                    <Button
                      style={{ flexGrow: 1 }}
                      disabled={isLoading ? true : false}
                      disabledWithReason={preventSaving && (runningProgramSetDisableReason || pendingChangeDisableReason)}
                    >
                      {isLoading ? <div className="primary-loader light"></div> : 'Save'}
                    </Button>
                  </ButtonGroup>
                </ButtonWrapper>
              </Form>
            </FormProvider>
          </>
        )}
    </Modal>
  )
}
