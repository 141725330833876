import '@/Utilities/Form/Yup'

import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import classNames from 'classnames'
import { compact,
  filter,
  find,
  get,
  includes,
  isEmpty,
  isFunction,
  map,
  padStart,
  some } from 'lodash-es'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'

import AlertContent from '@/Components/alerts/AlertContent'
import { Anchor, Button } from '@/Components/form/Buttons'
import Modal from '@/Components/Modal'
import Conditions from '@/Components/modals/program/Conditions'
import Delays from '@/Components/modals/program/Delays'
import Fertigation from '@/Components/modals/program/Fertigation'
import General from '@/Components/modals/program/General'
import InputOutputs from '@/Components/modals/program/InputOutputs'
import Tabs from '@/Components/Tabs'
import { pageAlertState } from '@/Config/Atoms/General'
import { getProgramSets } from '@/Utilities/Accessors/ProgramSets'
import { publish } from '@/Utilities/Events'
import { flattenSelectValues, formatKeys } from '@/Utilities/Form/Formatter'
import useApiClient from '@/Utilities/useApiClient'
import useEntityMonitor from '@/Utilities/useEntityMonitor'
import useFormRevalidate from '@/Utilities/useFormRevalidate'
import usePendingChange from '@/Utilities/usePendingChange'
import programSchema from '@/Utilities/Validation/ProgramSchema'

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`

const ButtonGroup = styled.div`
  display: flex;
  width: calc(50% - 5px);
`

const Form = styled.form`

  label {
    margin-top: 20px;
  }

  .delays {
    label {
      margin-top: 0;
    }
  }

  .buttons {
    margin-top: 40px;
  }
`

export default function ProgramModal(props) {
  const apiClient = useApiClient()
  const [selectedTab, setSelectedTab] = useState('general')
  const [isLoading, setIsLoading] = useState(false)
  const [selectedProgramSet, setSelectedProgramSet] = useState(null)
  const [programSets, setProgramSets] = useState([])
  const [programSetOptions, setProgramSetOptions] = useState([])
  const [allMainLine, setAllMainLine] = useState([])
  const [selectedMainLine, setSelectedMainLine] = useState([])
  const [mainLineOptions, setMainLineOptions] = useState([])
  const [mainLinesLoading, setMainLinesLoading] = useState(false)
  const [setupLoading, setSetupLoading] = useState(true)
  const [inputOutputFlowMeterOptions, setInputOutputFlowMeterOptions] = useState([])
  const [inputOutputFlowMetersLoading, setInputOutputFlowMetersLoading] = useState(false)
  const [inputOutputValveOptions, setInputOutputValveOptions] = useState([])
  const [inputOutputValvesLoading, setInputOutputValvesLoading] = useState(false)
  const [tabErrors, setTabErrors] = useState({})
  const form = useRef(null)
  const navigate = useNavigate()
  const [mainLineAndAuxInputOutputs, setMainLineAndAuxInputOutputs] = useState({})
  const [allInputOutputs, setAllInputOutputs] = useState([])
  const setAlert = useSetRecoilState(pageAlertState)
  const {
    isInRunningState,
    getProgramDisabledReason,
  } = useEntityMonitor()

  const {
    hasPendingChange,
    getPendingChangeDisabledReason,
  } = usePendingChange()

  const isEditing = props.data?.isEditing

  const program = useMemo(() => {
    let program = null

    if (props.data?.program) {
      program = props.data.program
    }

    return program
  }, [props.data?.program])

  const preventSaving = isInRunningState('program', program?.id) || hasPendingChange(program)
  const runningProgramDisableReason = getProgramDisabledReason('program', program?.id)
  const preventAddingProgram = isInRunningState('programSet', selectedProgramSet?.id)
  const runningProgramSetDisableReason = getProgramDisabledReason('programSet', selectedProgramSet?.id)
  const pendingChangeDisableReason = getPendingChangeDisabledReason(program)

  const {
    trigger,
    reset,
    watch,
    setValue,
    getValues,
    control,
    register,
    handleSubmit,
    formState,
  } = useForm({
    resolver: yupResolver(programSchema(selectedProgramSet)),
    defaultValues: {
      fertigationPumps: [],
      delays: [],
      siteId: program?.programSet?.siteId || '',
      mainLineId: program?.mainLine ? {
        value: program.mainLine.id,
        label: program.mainLine.name,
      } : null,
    },
  })

  // Perform form revalidation when dirty and any field is updated
  const { submitErrorHandler } = useFormRevalidate({
    watch,
    formState,
    trigger,
    schema: programSchema(selectedProgramSet),
    revalidateWhen: formState.isDirty && formState.isSubmitted,
    setSelectedTab,
  })

  const {
    errors,
    dirtyFields,
  } = formState

  const selectedSiteId = watch('siteId')
  const selectedMainLineId = watch('mainLineId')
  const selectedProgramSetId = watch('programSetId')
  const programDisabled = watch('disabled')

  useEffect(() => {
    if (!isEmpty(programSets) && !isEmpty(selectedProgramSetId)) {
      const current = find(programSets, ['id', selectedProgramSetId.value])

      setSelectedProgramSet(formatKeys(current, 'camel'))
    }
  }, [selectedProgramSetId, programSets])

  const {
    fields: fertigationPumpsFields,
    append: fertigationPumpsAppend,
    remove: fertigationPumpsRemove,
  } = useFieldArray({
    control,
    name: 'fertigationPumps',
  })

  const selectedPumpIds = useWatch({
    control,
    name: map(fertigationPumpsFields, (field, index) => {
      return `fertigationPumps.${index}.inputOutputId.value`
    }),
  })

  const selectedFlowMeterIds = useWatch({
    control,
    name: map(fertigationPumpsFields, (field, index) => {
      return `fertigationPumps.${index}.fertigationFlowMeterId.value`
    }),
  })

  const {
    fields: delaysFields,
    append: delaysAppend,
    remove: delaysRemove,
  } = useFieldArray({
    control,
    name: 'delays',
  })

  const tabs = useMemo(() => {
    return [
      {
        title: 'General',
        key: 'general',
        component: General,
        hasError: tabErrors['general'],
      },
      {
        title: 'I/Os',
        key: 'io',
        component: InputOutputs,
        hasError: tabErrors['io'],
      },
      {
        title: 'Delays',
        key: 'delays',
        component: Delays,
        hasError: tabErrors['delays'],
      },
      {
        title: 'Conditions',
        key: 'conditions',
        component: Conditions,
        hasError: tabErrors['conditions'],
      },
      {
        title: 'Fertigation',
        key: 'fertigation',
        component: Fertigation,
        hasError: tabErrors['fertigation'],
      },
    ]
  }, [tabErrors])

  useEffect(() => {
    (async () => {
      let programSetData = await getProgramSets(apiClient)
      programSetData = formatKeys(programSetData, 'camel')

      let setOptions = map(programSetData.programSets, (programSet) => {
        return {
          value: programSet.id,
          label: programSet.name,
        }
      })

      setProgramSets(programSetData.programSets)
      setProgramSetOptions(setOptions)
      setSetupLoading(false)
    })()
  }, [])

  const getMainLines = useCallback(async () => {
    const query = new URLSearchParams([
      ['pageSize', 1000],
      ['siteIds[]', selectedSiteId || ''],
      ['with', 'flowMeter'],
    ])

    try {
      const { data } = await apiClient.get(`/main-line/query?${query}`)

      setAllMainLine(data.mainLines.data)

      const mainLineOptions = map(data.mainLines.data, (mainLine) => {
        if (selectedProgramSet.irrigationBasis === 'quantity' && !mainLine.flow_meter_id) {
          return null
        }

        return {
          value: mainLine.id,
          label: mainLine.name,
        }
      })

      // Remove all falsy values
      const filteredMainLineOptions = compact(mainLineOptions)

      setMainLineOptions(filteredMainLineOptions)
      setMainLinesLoading(false)
    } catch (error) {
      Sentry.captureException(error)
      setAlert({
        type: 'error',
        content: 'Failed to retrieve main lines.',
      })
    }
  }, [selectedProgramSet])

  useEffect(() => {
    if (selectedSiteId) {
      (async() => {
        let inputOutputs = []

        const query = new URLSearchParams([
          ['pageSize', 1000],
          ['siteIds[]', selectedSiteId || ''],
          ['with[]', 'mainLines'],
          ['with[]', 'mainLineFlowMeters'],
          ['options[]', 'notMainLineFlowMeter'],
        ])

        try {
          const { data } = await apiClient.get(`/input-output/query?${query}`)

          inputOutputs = filter(formatKeys(data.inputOutputs.data, 'camel'), ['active', true])
        } catch (error) {
          Sentry.captureException(error)

          setAlert({
            type: 'error',
            content: 'Failed to retrieve I/Os.',
          })
        }

        setAllInputOutputs(inputOutputs)
      })()
    }
  }, [selectedSiteId])

  const getInputOutputs = useCallback(async () => {
    setInputOutputValvesLoading(true)
    setInputOutputFlowMetersLoading(true)

    if (allInputOutputs) {
      // Aux & Main Line I/Os
      const inputOutputs = filter(allInputOutputs, (inputOutput) => {
        return (inputOutput.auxiliary || some(inputOutput.mainLines, { id: selectedMainLineId?.value }))
      })

      setMainLineAndAuxInputOutputs(inputOutputs)

      // Valves
      const inputOutputValveOptions = map(filter(allInputOutputs, ['detailsType', 'inputOutput.digitalOutputValve']), (inputOutput) => {
        return {
          value: inputOutput.id,
          label: inputOutput.name,
        }
      })

      setInputOutputValveOptions(inputOutputValveOptions)
      setInputOutputValvesLoading(false)

      // Flow Meters
      let inputOutputFlowMeterOptions = filter(allInputOutputs, (inputOutput) => {
        return (
          includes(['inputOutput.digitalFlowMeter', 'inputOutput.analogFlowMeter'], inputOutput.detailsType) &&
          isEmpty(inputOutput.mainLineFlowMeters) &&
          !includes(selectedFlowMeterIds, inputOutput.id)
        )
      },
      )

      setInputOutputFlowMeterOptions(inputOutputFlowMeterOptions)
      setInputOutputFlowMetersLoading(false)

      // Reset Existing Selection
      if (!find(inputOutputValveOptions, getValues('mainValveId'))) {
        setValue('mainValveId', null)
      }

      if (!find(inputOutputFlowMeterOptions, getValues('flowMeterId'))) {
        setValue('flowMeterId', null)
      }
    }
  }, [
    allInputOutputs,
    selectedSiteId,
    selectedMainLineId,
    selectedFlowMeterIds,
  ])

  useEffect(() => {
    if (!isEmpty(allMainLine)) {
      const matchedMainLine = find(allMainLine, ['id', selectedMainLineId?.value])

      setSelectedMainLine(matchedMainLine)
    }
  }, [selectedMainLineId])

  useEffect(() => {
    if (!isEmpty(allInputOutputs) && selectedSiteId) {
      getInputOutputs()
    }
  }, [
    allInputOutputs,
    selectedSiteId,
    selectedMainLineId,
    selectedFlowMeterIds,
  ])

  useEffect(() => {
    if (selectedSiteId && selectedProgramSet) {
      getMainLines()
    }
  }, [selectedSiteId, selectedProgramSet])

  const tabChange = useCallback((value) => {
    setSelectedTab(value)
  }, [setSelectedTab])

  const onSubmit = async (data, afterSave = null) => {
    data = flattenSelectValues(data)
    data.fertigationPumps = map(data.fertigationPumps, (pump) => {
      if (get(selectedProgramSet, 'irrigationBasis') === 'time') {
        pump.fertigationWaterBeforeTime = `${padStart(pump.fertigationWaterBeforeTimeHours, 2, 0)}:${padStart(pump.fertigationWaterBeforeTimeMinutes, 2, 0)}:00`
        pump.fertigationWaterAfterTime = `${padStart(pump.fertigationWaterAfterTimeHours, 2, 0)}:${padStart(pump.fertigationWaterAfterTimeMinutes, 2, 0)}:00`

        delete pump.fertigationWaterBeforeTimeHours
        delete pump.fertigationWaterBeforeTimeMinutes
        delete pump.fertigationWaterAfterTimeHours
        delete pump.fertigationWaterAfterTimeMinutes
      }

      pump.fertigationDuration = pump.fertigationDurationHours || pump.fertigationDurationMinutes || pump.fertigationDurationSeconds
        ? `${padStart(pump.fertigationDurationHours, 2, 0)}:${padStart(pump.fertigationDurationMinutes, 2, 0)}:${padStart(pump.fertigationDurationSeconds, 2, 0)}`
        : null

      delete pump.fertigationDurationHours
      delete pump.fertigationDurationMinutes
      delete pump.fertigationDurationSeconds

      return flattenSelectValues(pump)
    })
    data.fertigationPumps = filter(data.fertigationPumps, (pump) => {
      return !!pump.inputOutputId
    })
    data.runtime = `${padStart(data.runtimeHours, 2, 0)}:${padStart(data.runtimeMinutes, 2, 0)}:${padStart(data.runtimeSeconds, 2, 0)}`,
    data = formatKeys(data, 'snake')

    setAlert(null)
    setIsLoading(true)

    try {
      let { data: responseData } = await apiClient.post(`/program/${isEditing ? `update/${program?.id}`: 'create'} `, data)

      if (isFunction(afterSave)) {
        await afterSave()
      }

      if (responseData.success) {
        if (isFunction(props.data?.onSave)) {
          props.data.onSave()
        } else {
          navigate(`/program/manage/${responseData.program.id}`)
        }

        setAlert({
          type: 'info',
          content: `Attempting to ${isEditing ? 'update' : 'create'} Program ${responseData.program.name}.`,
        })

        publish('programCreated')

        props.close()
      }
    } catch (error) {
      setAlert({
        type: 'error',
        content: 'An error has occured and we were not able to save this program. Please try again.',
      })
      Sentry.captureException(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      title={props.data?.site ? `${isEditing ? 'Edit' : 'Add'} a program for ${props.data?.site.name}` : `${isEditing ? 'Edit' : 'Add'} program`}
      close={props.close}
      closeOnOutsideClick={props.closeOnOutsideClick}
    >
      {setupLoading ?
        <div className="flex h-80 items-center justify-center">
          <div className="primary-loader"></div>
        </div> : (
          <>
            {(preventSaving || preventAddingProgram) && (
              <AlertContent className="mb-4">
                {preventSaving && (runningProgramDisableReason || runningProgramSetDisableReason || pendingChangeDisableReason)}
              </AlertContent>
            )}

            <Tabs
              currentlySelected={selectedTab}
              defaultTab={get(props, 'data.activeTab', 'general')}
              tabs={tabs}
              onChange={tabChange}
              disabled={!selectedSiteId}
              tooltipMessage={!selectedSiteId ? 'Please select a program set' : null}
              tooltipPlacement="bottom"
            />
            <FormProvider
              control={control}
              dirtyFields={dirtyFields}
              errors={errors}
              getValues={getValues}
              handleSubmit={handleSubmit}
              register={register}
              reset={reset}
              setValue={setValue}
              trigger={trigger}
              watch={watch}
              fertigationPumpsFields={fertigationPumpsFields}
              fertigationPumpsAppend={fertigationPumpsAppend}
              fertigationPumpsRemove={fertigationPumpsRemove}
              delaysFields={delaysFields}
              delaysAppend={delaysAppend}
              delaysRemove={delaysRemove}
            >
              <Form onSubmit={handleSubmit(onSubmit, submitErrorHandler)} autoComplete="off" ref={form}>
                {map(tabs, (tab) => {
                  return (
                    <div className={classNames({ hidden: tab.key != selectedTab })} key={tab.key} id={tab.key}>
                      <tab.component
                        {...props}
                        selectedMainLine={selectedMainLine}
                        allInputOutputs={allInputOutputs}
                        mainLineAndAuxInputOutputs={mainLineAndAuxInputOutputs}
                        selectedProgramSet={selectedProgramSet}
                        programSets={programSets}
                        programSetOptions={programSetOptions}
                        setupLoading={setupLoading}
                        mainLineOptions={mainLineOptions}
                        mainLinesLoading={mainLinesLoading}
                        inputOutputFlowMeterOptions={inputOutputFlowMeterOptions}
                        inputOutputFlowMetersLoading={inputOutputFlowMetersLoading}
                        inputOutputValveOptions={inputOutputValveOptions}
                        inputOutputValvesLoading={inputOutputValvesLoading}
                        selectedPumpIds={selectedPumpIds}
                        selectedSiteId={selectedSiteId}
                        tab={tab.key}
                        tabErrors={tabErrors}
                        setTabErrors={setTabErrors}
                        programDisabled={programDisabled}
                      />
                    </div>
                  )
                })}

                {programDisabled && (
                  <div>
                    <AlertContent
                      title={'Program disabled'}
                      type={'warning'}
                      hideIcon={false}
                      className="mt-5"
                    >
                      <p>
                        This program will not run.
                      </p>
                    </AlertContent>
                  </div>
                )}

                <ButtonWrapper className="buttons">
                  <Anchor
                    style={{ width: 'calc(50% - 5px)' }}
                    className="transparent"
                    onClick={() => {
                      setAlert(null)
                      reset()
                      props.close()
                    }}
                  >
                    Close
                  </Anchor>

                  <ButtonGroup>
                    <Button
                      style={{ flexGrow: 1 }}
                      disabled={isLoading ? true : false}
                      disabledWithReason={preventSaving && (runningProgramDisableReason || runningProgramSetDisableReason || pendingChangeDisableReason)}
                    >
                      {isLoading ? <div className="primary-loader light"></div> : 'Save'}
                    </Button>
                  </ButtonGroup>
                </ButtonWrapper>
              </Form>
            </FormProvider>
          </>
        )
      }
    </Modal>
  )
}
