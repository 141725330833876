import '@/Utilities/Form/Yup'

import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import classNames from 'classnames'
import { filter, find, includes, isEmpty, isFunction, map, padStart } from 'lodash-es'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import styled from 'styled-components'

import AlertContent from '@/Components/alerts/AlertContent'
import { Anchor, Button } from '@/Components/form/Buttons'
import Modal from '@/Components/Modal'
import FlowControl from '@/Components/modals/mainLine/FlowControl'
import General from '@/Components/modals/mainLine/General'
import InputOutputs from '@/Components/modals/mainLine/InputOutputs'
import Tabs from '@/Components/Tabs'
import { isLoadingState, pageAlertState } from '@/Config/Atoms/General'
import { publish } from '@/Utilities/Events'
import { defaultValue, flattenSelectValues, formatKeys, toOptions } from '@/Utilities/Form/Formatter'
import useApiClient from '@/Utilities/useApiClient'
import useEntityMonitor from '@/Utilities/useEntityMonitor'
import useFormRevalidate from '@/Utilities/useFormRevalidate'
import mainLineSchema from '@/Utilities/Validation/MainLineSchema'

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`

const ButtonGroup = styled.div`
  display: flex;
  width: calc(50% - 5px);
`

const Form = styled.form`
  label {
    margin-top: 20px;
  }

  .buttons {
    margin-top: 40px;
  }
`

function MainLineModal(props) {
  const apiClient = useApiClient()

  const [selectedTab, setSelectedTab] = useState('general')
  const [inputOutputFlowMeters, setInputOutputFlowMeters] = useState()
  const [inputOutputFlowMeterOptions, setInputOutputFlowMeterOptions] = useState()
  const [inputOutputFlowMetersLoading, setInputOutputFlowMetersLoading] = useState(false)
  const [inputOutputValveOptions, setInputOutputValveOptions] = useState()
  const [inputOutputValvesLoading, setInputOutputValvesLoading] = useState(false)
  const [tabErrors, setTabErrors] = useState({})
  const [setupLoading, setSetupLoading] = useState(true)
  const [siteOptions, setSiteOptions] = useState()
  const [sitesLoading, setSitesLoading] = useState(true)
  const [isLoading, setIsLoading] = useRecoilState(isLoadingState)
  const setAlert = useSetRecoilState(pageAlertState)
  const [siteInputOutputs, setSiteInputOutputs] = useState([])

  const tabs = useMemo(() => {
    return [
      {
        title: 'General',
        key: 'general',
        component: General,
        hasError: tabErrors['general'],
      },
      {
        title: 'I/Os',
        key: 'io',
        component: InputOutputs,
        hasError: tabErrors['io'],
      },
      {
        title: 'Flow control',
        key: 'flowControl',
        component: FlowControl,
        hasError: tabErrors['flowControl'],
      },
    ]
  }, [tabErrors])

  const {
    isInRunningState,
    getRelatedProgramNames,
  } = useEntityMonitor()

  const navigate = useNavigate()
  const form = useRef(null)

  const isEditing = props.data?.isEditing || false
  const mainLine = props.data?.mainLine || {}
  const defaultSiteValue = useMemo(() => {
    return mainLine && mainLine.site ? defaultValue(mainLine, 'site') : find(siteOptions, ['value', mainLine?.siteId])
  }, [mainLine, siteOptions])

  const preventSaving = isInRunningState('mainLine', mainLine?.id)
  const runningProgramNames = getRelatedProgramNames('mainLine', mainLine?.id)

  const {
    control,
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
    trigger,
    watch,
    formState,
  } = useForm({
    resolver: yupResolver(mainLineSchema),
    defaultValues: { siteId: defaultSiteValue },
  })

  // Perform form revalidation when dirty and any field is updated
  const { submitErrorHandler } = useFormRevalidate({
    watch,
    formState,
    trigger,
    schema: mainLineSchema,
    revalidateWhen: formState.isDirty && formState.isSubmitted,
    setSelectedTab,
  })

  const {
    errors,
    dirtyFields,
  } = formState

  const selectedSiteId = watch('siteId')

  const getSites = async () => {
    try {
      const { data } = await apiClient.get('/sites')

      if (data && data.sites) {
        const siteOptions = map(data.sites, (site) => {
          return {
            value: site.id,
            label: site.name,
            isDisabled: !site.active,
          }
        })

        setSiteOptions(siteOptions)
        setSitesLoading(false)
      }
    } catch (error) {
      Sentry.captureException(error)
      setAlert({
        type: 'error',
        content: 'Failed to retrieve sites.',
      })
    }
  }

  const flowMeterTypeOptions = useMemo(() => {
    return [{
      value: 'physical',
      label: 'Physical',
    }]
  }, [])

  useEffect(() => {
    (async () => {
      await getSites()
      setSetupLoading(false)
    })()
  }, [])

  const getInputOutputs = async (selectedSiteId) => {
    setInputOutputValvesLoading(true)
    setInputOutputFlowMetersLoading(true)

    const query = new URLSearchParams([
      ['pageSize', 1000],
      ['siteIds[]', selectedSiteId.value || ''],
      ['with[]', 'mainLines'],
      ['with[]', 'mainLineFlowMeters'],
      ['with[]', 'mainLineMainValve'],
      ['options[]', 'notFertPumpFlowMeter'],
    ])

    try {
      const { data } = await apiClient.get(`/input-output/query?${query}`)

      if (data && data.inputOutputs) {
        const inputOutputs = filter(formatKeys(data.inputOutputs.data, 'camel'), ['active', true])

        setSiteInputOutputs(inputOutputs)

        // Valves
        let inputOutputValveOptions = filter(inputOutputs, (inputOutput) => {
          if (inputOutput.type !== 'digitalOutputValve') {
            return false
          }

          const isSelectedMainValve = isEditing && mainLine.mainValveId == inputOutput.id

          return isSelectedMainValve || inputOutput.auxiliary || (isEmpty(inputOutput.mainLineMainValve) && isEmpty(inputOutput.mainLines))
        })

        inputOutputValveOptions = toOptions(inputOutputValveOptions, ['name', 'label'], ['id', 'value'])

        setInputOutputValveOptions(inputOutputValveOptions)
        setInputOutputValvesLoading(false)

        // Flow Meters
        let inputOutputFlowMeterOptions = filter(inputOutputs, (inputOutput) => {
          const isFlowMeterType = includes(['digitalFlowMeter', 'analogFlowMeter'], inputOutput.type)
          const isEmptyFlowMeterOrMainLine = isEmpty(inputOutput.mainLineFlowMeters) || inputOutput.id == mainLine.flowMeterId

          return isFlowMeterType && isEmptyFlowMeterOrMainLine
        })

        setInputOutputFlowMeters(inputOutputFlowMeterOptions)

        inputOutputFlowMeterOptions = toOptions(inputOutputFlowMeterOptions, ['name', 'label'], ['id', 'value'])

        setInputOutputFlowMeterOptions(inputOutputFlowMeterOptions)
        setInputOutputFlowMetersLoading(false)

        // Reset Existing Selection
        if (!find(inputOutputValveOptions, getValues('mainValveId'))) {
          setValue('mainValveId', null)
        }

        if (!find(inputOutputFlowMeterOptions, getValues('flowMeterId'))) {
          setValue('flowMeterId', null)
        }
      }
    } catch (error) {
      Sentry.captureException(error)
      setAlert({
        type: 'error',
        content: 'Failed to retrieve I/Os.',
      })
    }
  }

  useEffect(() => {
    if (selectedSiteId) {
      getInputOutputs(selectedSiteId)
    }
  }, [selectedSiteId])

  const tabChange = useCallback((value) => {
    setSelectedTab(value)
  }, [setSelectedTab])

  const onSubmit = async (data, afterSave = null) => {
    data = flattenSelectValues(data)

    // Set time
    data.maximumSuspendTime = `00:${padStart(data.maximumSuspendTimeMinutes, 2, 0)}:${padStart(data.maximumSuspendTimeSeconds, 2, 0)}`
    delete data.maximumSuspendTimeMinutes
    delete data.maximumSuspendTimeSeconds

    data = formatKeys(data, 'snake')

    setAlert(null)
    setIsLoading(true)

    try {
      let { data: responseData } = await apiClient.post(`/main-line/${isEditing ? `update/${props.data.mainLine.id}` : 'create'} `, data)

      if (isFunction(afterSave)) {
        await afterSave()
      }

      if (responseData.success) {
        if (isFunction(props.data?.onSave)) {
          props.data.onSave()
        } else {
          navigate('/main-lines')
        }

        setAlert({
          type: 'info',
          content: `Attempting to ${isEditing ? 'update' : 'create'} Main Line ${responseData.mainLine.name}.`,
        })

        publish('mainLineCreated')

        props.close()
      }
    } catch (error) {
      setAlert({
        type: 'error',
        content: 'An error has occured and we were not able to save this main line. Please try again.',
      })
      Sentry.captureException(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      title={`${isEditing ? 'Edit': 'Add'} main line`}
      close={props.close}
      closeOnOutsideClick={props.closeOnOutsideClick}
    >
      {setupLoading ?
        <div className="flex h-80 items-center justify-center">
          <div className="primary-loader"></div>
        </div> : (
          <>
            {preventSaving && (
              <AlertContent className="mb-4">
                You cannot edit or delete this as the following programs are running: {runningProgramNames}
              </AlertContent>
            )}

            <Tabs
              currentlySelected={selectedTab}
              defaultTab="general"
              tabs={tabs}
              disabled={isEmpty(selectedSiteId)}
              tooltipMessage={isEmpty(selectedSiteId) ? 'Please select a site': null}
              onChange={tabChange}
            />
            <FormProvider
              control={control}
              dirtyFields={dirtyFields}
              errors={errors}
              getValues={getValues}
              handleSubmit={handleSubmit}
              register={register}
              reset={reset}
              setValue={setValue}
              trigger={trigger}
              watch={watch}
            >
              <Form onSubmit={handleSubmit(onSubmit, submitErrorHandler)} autoComplete="off" ref={form}>
                {map(tabs, (tab) => {
                  return (
                    <div className={classNames({ hidden: tab.key != selectedTab })} key={tab.key} id={tab.key}>
                      <tab.component
                        {...props}
                        isEditing={isEditing}
                        flowMeterTypeOptions={flowMeterTypeOptions}
                        inputOutputFlowMeters={inputOutputFlowMeters}
                        inputOutputFlowMeterOptions={inputOutputFlowMeterOptions}
                        inputOutputFlowMetersLoading={inputOutputFlowMetersLoading}
                        inputOutputValveOptions={inputOutputValveOptions}
                        inputOutputValvesLoading={inputOutputValvesLoading}
                        siteOptions={siteOptions}
                        sitesLoading={sitesLoading}
                        siteInputOutputs={siteInputOutputs}
                        tabErrors={tabErrors}
                        setTabErrors={setTabErrors}
                      />
                    </div>
                  )
                })}

                <ButtonWrapper className="buttons">
                  <Anchor
                    style={{ width: 'calc(50% - 5px)' }}
                    className="transparent"
                    onClick={() => {
                      setAlert(null)
                      reset()
                      props.close()
                    }}
                  >
                    Close
                  </Anchor>

                  <ButtonGroup>
                    <Button
                      style={{ flexGrow: 1 }}
                      disabled={isLoading ? true : false}
                      disabledWithReason={preventSaving && runningProgramNames}
                    >
                      {isLoading ? <div className="primary-loader light"></div> : 'Save'}
                    </Button>
                  </ButtonGroup>
                </ButtonWrapper>
              </Form>
            </FormProvider>
          </>
        )}
    </Modal>
  )
}

export default MainLineModal
