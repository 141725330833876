import * as Sentry from '@sentry/react'
import { find, findIndex, flatMap, get, groupBy, head, isEmpty, map, sortBy, toString } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'

import PermissionGuard from '@/Components/auth/PermissionGuard'
import PageHeaderCard from '@/Components/card/PageHeaderCard'
import { Anchor } from '@/Components/form/Buttons'
import SelectField from '@/Components/form/Select'
import Select from '@/Components/headless/form/Select'
import SiteTime from '@/Components/site/SiteTime'
import InputOutputsBySite from '@/Components/tables/InputOutputsBySite'
import ProgramSetsAll from '@/Components/tables/programs/ProgramSetsAll'
import Tabs from '@/Components/Tabs'
import { modalState } from '@/Config/Atoms/General'
import Alarms from '@/Pages/site/overview/tabs/Alarms'
import Overview from '@/Pages/site/overview/tabs/Overview'
import { getByPath } from '@/Utilities/Accessors/Helpers'
import { standardActions } from '@/Utilities/Events'
import { formatKeys } from '@/Utilities/Form/Formatter'
import useApiClient from '@/Utilities/useApiClient'
import useAuth from '@/Utilities/useAuth'
import useEventSubscriber from '@/Utilities/useEventSubscriber'
import useTitle from '@/Utilities/useTitle'

function SiteView() {
  const auth = useAuth()
  const navigate = useNavigate()
  const urlParams = useParams()
  const apiClient = useApiClient()

  const [programStats, setProgramStats] = useState([])
  const [programSetStats, setProgramSetStats] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [programSetSliderIndex, setProgramSetSliderIndex] = useState(0)
  const [programSliderIndex, setProgramSliderIndex] = useState(0)
  const [selectedTab, setSelectedTab] = useState('overview')
  const [site, setSite] = useState([])
  const [sites, setSites] = useState([])
  const [activeInputOutputAlarms, setActiveInputOutputAlarms] = useState([])
  const setModal = useSetRecoilState(modalState)

  const { setSubscriberModelMap } = useEventSubscriber([
    'program',
    'programSet',
    'inputOutput',
    'fertigationPump',
    'alarm',
    'remoteUnit',
  ], [
    ...standardActions,
    'stateStat',
    'alarm',
    'clear',
  ], () => {
    getSite()
  })

  useTitle(['Map', site?.name])

  const getDisplayedProgram = useCallback(async (siteId) => {
    try {
      let { data } = await apiClient.get(`/program/current-or-previous?site_id=${siteId}`)

      if (data.success) {
        return data.program
      }
    } catch (error) {
      Sentry.captureException(error)
    }

    return null
  }, [])

  useEffect(() => {
    (async () => {
      const query = new URLSearchParams([['pageSize', 1000], ['with[]', 'area']])

      try {
        let { data } = await apiClient.get(`/site/query?${query}`)

        if (data && data.success) {
          const siteData = data.sites.data

          setSites(siteData)
        }
      } catch (error) {
        Sentry.captureException(error)
      }
    })()
  }, [])

  const getSite = useCallback(async () => {
    const query = new URLSearchParams([
      ['with[]', 'activeAlarms'],
      ['with[]', 'blocks'],
      ['with[]', 'siteCards'],
      ['with[]', 'area.activeAlarms'],
      ['with[]', 'inputOutputs.activeAlarms'],
      ['with[]', 'inputOutputs.details.manufacturerModel'],
      ['with[]', 'programSets.blocks'],
      ['with[]', 'programSets.programs.operationalStatuses'],
      ['with[]', 'programSets.programs.inputOutputs.mainLines'],
      ['with[]', 'programSets.programs.fertigationPumps.inputOutput'],
      ['with[]', 'programSets.programs.fertigationPumps.flowMeter'],
      ['with[]', 'programSets.programs.fertigationPumps.operationalStatuses'],
      ['with[]', 'programSets.programs.mainLine.flowMeter'],
      ['with[]', 'programSets.programs.mainLine.inputOutputs'],
      ['with[]', 'programSets.programs.activeAlarms'],
      ['programSetState', true],
    ])

    try {
      setIsLoading(true)
      let { data } = await apiClient.get(`/site/view/${urlParams.id}?${query}`)

      if (data && data.success) {
        data = formatKeys({ ...data }, 'camel')

        const activeProgramSetIndex = findIndex(data.site.programSets, (set) => {
          return get(set, 'state.isActive', false)
        })

        const activeProgramIndex = findIndex(flatMap(data.site.programSets, (programSet) => {
          return programSet.programs
        }), (program) => {
          return get(program, 'state.isActive', false)
        })

        const siteData = {
          ...data.site,
          programSetIndex: isEmpty(activeProgramSetIndex) ? 0 : activeProgramSetIndex,
          programIndex: isEmpty(activeProgramIndex) ? 0 : activeProgramIndex,
        }

        setSite(siteData)

        // Get all current program stats
        const currentProgramStats = getByPath(siteData, 'programSets.*.programs.*.stats')
        setProgramStats(currentProgramStats)

        // Get all current program stats
        const currentProgramSetStats = getByPath(siteData, 'programSets.*.stats')
        setProgramSetStats(currentProgramSetStats)

        // Get all program and program set ID's
        const programSetIds = getByPath(siteData, 'programSets.*.id')
        const programIds = getByPath(siteData, 'programSets.*.programs.*.id')

        setSubscriberModelMap({
          programSet: programSetIds,
          program: programIds,
        })

        // Update active inputOutput alarms
        const inputOutputs = getByPath(siteData, 'inputOutputs.*.activeAlarms')

        setActiveInputOutputAlarms(inputOutputs)
      }
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      setIsLoading(false)
    }
  }, [
    urlParams.id,
    setSite,
    getDisplayedProgram,
  ])

  useEffect(() => {
    getSite()
  }, [urlParams.id])

  const filterTabs = useMemo(() => {
    return [
      {
        title: 'Overview',
        key: 'overview',
      },
      {
        title: 'Programs',
        key: 'programs',
      },
      {
        title: 'I/Os',
        key: 'inputOutputs',
      },
      {
        title: 'Alarms',
        key: 'alarms',
      },
    ]
  }, [])

  const siteOptions = useMemo(() => {
    const groupedByArea = groupBy(sites, (site) => {
      return site.area.id
    })

    return sortBy(map(groupedByArea, (sitesByArea) => {
      const siteOptions = map(sitesByArea, (site) => {
        return {
          label: site.name,
          value: site.id,
        }
      })

      return {
        label: head(sitesByArea).area.name,
        options: siteOptions,
      }
    }), ['label'])
  }, [sites])

  const findSelectedSite = (sites, value) => {
    const allOptions = flatMap(sites, (site) => {
      return site.options
    })

    return find(allOptions, { value })
  }

  if (isEmpty(sites)) {
    return <></>
  }

  return (
    !isEmpty(site) && (
      <div className="px-6">
        <PageHeaderCard
          title={
            <>
              <div className="mb-4 flex items-center text-sm md:hidden">
                <span className="mr-1 font-bold">Site Time:</span>
                <SiteTime site={site} />
              </div>

              <div className="grid content-center md:grid-cols-2-left-auto">
                <div className="md:min-w-64 md:max-w-64 lg:min-w-72 xl:max-w-72">
                  <SelectField
                    autoFocus={true}
                    menuIsOpen={true}
                    isSearchable={true}
                    display="flex"
                    width="auto"
                    placeholder="Search"
                    options={siteOptions}
                    value={findSelectedSite(siteOptions, site.id)}
                    onChange={(option) => {
                      if (toString(option.value) !== site.id) {
                        navigate(`/site/overview/${option.value}`)
                      }
                    }}
                  />
                </div>
                <div className="ml-4 hidden items-center text-sm md:flex">
                  <span className="mr-2 font-bold">Site Time:</span>
                  <SiteTime site={site} />
                </div>
              </div>
            </>
          }
          className="mb-2"
        >
          <div className="hidden md:flex">
            <Tabs
              currentlySelected={selectedTab}
              defaultTab="overview"
              tabs={filterTabs}
              onChange={setSelectedTab}
            />
          </div>

          <div className="block md:hidden">
            <Select
              className="w-full rounded-md"
              defaultValue={head(filterTabs)}
              options={filterTabs}
              labelProperty="title"
              onChange={(selected) => {
                if (selected.key !== selectedTab) {
                  setSelectedTab(selected.key)
                }
              }}
            />
          </div>
        </PageHeaderCard>

        {(get(site, `programSets.${site.programSetIndex}.programs`, []).length > 0) ? (
          <>
            {selectedTab === 'overview' && (
              <Overview
                programSetSliderIndex={programSetSliderIndex}
                programSliderIndex={programSliderIndex}
                programStats={programStats}
                programSetStats={programSetStats}
                setDashboardSelectedTab={setSelectedTab}
                setProgramSetSliderIndex={setProgramSetSliderIndex}
                setProgramSliderIndex={setProgramSliderIndex}
                site={site}
                sites={sites}
                activeInputOutputAlarms={activeInputOutputAlarms}
                updateDashboard={getSite}
                isLoading={isLoading}
              />
            )}

            {selectedTab === 'inputOutputs' && (
              <InputOutputsBySite siteId={site.id} site={site} />
            )}

            {selectedTab === 'programs' && (
              <ProgramSetsAll siteId={site.id} site={site} />
            )}

            {selectedTab === 'alarms' && (
              <Alarms programs={site.programs} />
            )}
          </>
        ) : (
          <div className="flex min-h-reporting-with-top-bar items-center justify-center">
            <div className="text-center">
              <i className="fa-sharp fa-regular fa-droplet-slash fa-8x text-slate-300"></i>
              <h3 className="mt-10 text-lg font-semibold">No programs found</h3>
              <p className="text-sm">Please add your first program set and program to this site</p>

              <PermissionGuard permission="update-program" auth={auth}>
                <div className="mt-3 flex flex-wrap justify-center gap-3">
                  <Anchor
                    style={{ width: '200px' }}
                    onClick={() => {
                      setModal({
                        name: 'set',
                        data: {
                          site: {
                            name: site.name,
                            id: site.id,
                          },
                          onSave: () => {
                            getSite()
                          },
                        },
                      })
                    }}
                  >
                    Add program set
                  </Anchor>
                </div>
              </PermissionGuard>
            </div>
          </div>
        )}
      </div>
    )
  )
}

export default SiteView
