import { includes, some, startCase, upperFirst } from 'lodash-es'
import moment from 'moment'
import { useCallback } from 'react'
import { useSetRecoilState } from 'recoil'

import { pageAlertState } from '@/Config/Atoms/General'
import { standardActions } from '@/Utilities/Events'

const friendlyModelNames = {
  inputOutput: 'I/O',
  mainLine: 'Main Line',
  programSet: 'Program Set',
}

const toastCache = {}

/**
 * `useToast` is a custom hook that manages on-screen toasts for specified events.
 *
 * It employs a cache to avoid showing duplicate toasts too frequently and uses Recoil to set
 * the toast state. The hook returns an object with a single function, `showToast`, which takes
 * a detail object ({ name, action, model, success, fepMessage, noAlert }) and displays an
 * toast if all necessary conditions are met.
 *
 * @returns {{ showToast: Function }} An object containing the `showToast` method for triggering toasts.
 */
export default function useToast() {
  const setAlert = useSetRecoilState(pageAlertState)

  const getModelName = useCallback((model) => {
    let modelName = friendlyModelNames[model]

    if (!modelName) {
      modelName = upperFirst(startCase(model))
    }

    return modelName
  }, [])

  /**
   * This function will only display an toast if there wasn't a similar (having the same type, duration and message)
   * one shown within the last 'duration' seconds. The toastCache is used to track toasts based on a unique
   * id generated from the type, duration, and content. If the cache does not have an entry corresponding to the
   * unique id, or if the previous toast was displayed more than 'duration' seconds ago, a new toast is set up.
   */
  const displayAlert = useCallback((type, duration, message) => {
    const uniqueId = `${type}:${duration}:${message}`
    const currentTime = moment()

    if (!toastCache[uniqueId] || currentTime.diff(toastCache[uniqueId], 'seconds') > duration) {
      setAlert({
        type,
        duration,
        content: message,
      })

      toastCache[uniqueId] = currentTime
    }
  }, [toastCache])

  const showToast = useCallback((detail) => {
    const {
      name,
      action,
      model,
      success = true,
      fepMessage = '',
    } = detail

    if (detail.noAlert) {
      return true
    }

    let actionIsPresent = some(standardActions, (standardAction) => {
      return includes(standardAction, action)
    })

    if (actionIsPresent) {
      const modelName = getModelName(model)

      let toastType = 'success'
      let toastMessage = `${modelName} "${name}" has been successfully ${action}d.`
      let toastDuration = 5

      // If unsuccessful
      if (success !== true) {
        toastType = 'error'
        toastMessage = `${modelName} "${name}" has not been ${action}d.`
        toastDuration = 10

        if (fepMessage) {
          toastMessage = `${toastMessage} Message: ${fepMessage}`
        }
      }

      displayAlert(toastType, toastDuration, toastMessage)
    }
  }, [])

  return { showToast }
}
