import Tippy from '@tippyjs/react'
import classNames from 'classnames'
import { clamp, find, get, head, isEmpty, map, replace, round } from 'lodash-es'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar'

import Card from '@/Components/common/Card'
import HorizontalProgressBar from '@/Components/common/progressbars/HorizontalProgressBar'
import Pill from '@/Components/pill/Pill'
import { statusColor } from '@/Utilities/StatusColor'
import useTimer from '@/Utilities/useTimer'

export default function FertigationCard({
  fertigationPump,
  displayedProgram,
}) {
  const [fertigationSecondsRemaining, setFertigationSecondsRemaining] = useState(0)
  const [timerOutOfSyncWarning, setTimerOutOfSyncWarning] = useState(false)

  const fertigationPumpStatus = useMemo(() => {
    if (fertigationPump && !isEmpty(fertigationPump.operationalStatuses)) {
      const statuses = get(fertigationPump, 'operationalStatuses')

      return head(statuses).key
    }

    return 'none'
  }, [fertigationPump])

  const fertigationPumpStatusColour = useMemo(() => {
    return statusColor(get(fertigationPump, 'operationalStatuses', []))
  }, [fertigationPump])
  // console.log(fertigationPumpStatusColour)
  const [
    fertigationFlowRate,
    fertigationFlowTotal,
    fertigationFlowRatePercentage,
  ] = useMemo(() => {
    const fertigationFlowStat = find(fertigationPump.stats, { key: 'flow' })
    const fertigationFlowTotal = find(fertigationPump.stats, { key: 'total' })?.value
    const fertigationRemainingTime = find(fertigationPump.stats, { key: 'remainingTime' })?.value

    let fertigationFlowRate = get(fertigationFlowStat, 'value', 0)
    let fertigationFlowRatePercentage = 0

    if (get(fertigationPump, 'flowMeter.type') === 'analogFlowMeter' && fertigationFlowRate) {
      fertigationFlowRatePercentage = clamp(fertigationFlowRate / get(fertigationPump, 'flowMeter.details.scaleMax', 0) * 100, 0, 100)
    }

    return [
      fertigationFlowRate,
      fertigationFlowTotal,
      fertigationFlowRatePercentage,
      fertigationRemainingTime,
    ]
  }, [displayedProgram, fertigationPump])

  const [fertigationRemaining, fertigationElapsedPercentage] = useMemo(() => {
    if (fertigationPump.fertigationBasis === 'time') {
      if (fertigationPumpStatus !== 'running' && fertigationPumpStatus !== 'paused' && fertigationPumpStatus !== 'post-fert') {
        return [get(fertigationPump, 'timing.expectedTotalTime', '00:00:00'), 0]
      }

      let remainingRuntime = '00:00:00'
      let remainingRuntimePercentage = 100

      const totalRuntimeSeconds = moment.duration(fertigationPump.fertigationDuration).asSeconds()

      if (fertigationSecondsRemaining > 0) {
        remainingRuntime = moment.utc(fertigationSecondsRemaining * 1000).format('HH:mm:ss')
        remainingRuntimePercentage = 100 - round(((fertigationSecondsRemaining / totalRuntimeSeconds) * 100), 4)
      }

      return [remainingRuntime, remainingRuntimePercentage]
    }

    if (fertigationPump.fertigationBasis === 'quantity') {
      if (fertigationPumpStatus !== 'running' && fertigationPumpStatus !== 'paused') {
        return [get(fertigationPump, 'fertigationQuantity'), 0]
      }

      let remainingQuantityPercentage = 100
      remainingQuantityPercentage = fertigationFlowTotal / fertigationPump.fertigationQuantity * 100

      return [get(fertigationPump, 'fertigationQuantity', 0), remainingQuantityPercentage]
    }
  }, [
    fertigationSecondsRemaining,
    fertigationPump,
    fertigationPumpStatus,
  ])

  if (fertigationPump.fertigationBasis === 'time') {
    useTimer({
      identifyingKey: 'inputOutputId',
      identifyingId: fertigationPump.inputOutputId,
      displayed: fertigationPump,
      stats: fertigationPump.stats,
      setSecondsRemaining: setFertigationSecondsRemaining,
      status: fertigationPumpStatus,
      setTimerOutOfSyncWarning: setTimerOutOfSyncWarning,
    })
  }

  return (
    <Card className="h-full">
      <Card.Header
        title="Fertigation"
        className="flex items-center justify-between gap-1 p-3.5"
      >
        <div className="flex flex-1">
          {displayedProgram?.disabled ? (
            <Pill color="#647087">Disabled</Pill>
          ) : fertigationPump?.operationalStatuses && (
            map(fertigationPump?.operationalStatuses, (status) => {
              return (
                <div key={status.key}>
                  <Pill color={status.color} hasDot={true} key={status.title}>{status.title}</Pill>
                </div>
              )
            })
          )}
        </div>
      </Card.Header>

      <Card.Body className="my-auto flex flex-col p-3.5">
        <div className="flex flex-col text-sm">
          <div className="flex justify-start">
            <div className="mr-1 text-gray-500">Booster:</div>{fertigationPump.inputOutput.name}
          </div>

          {fertigationPump.flowMeter && (
            <div className="flex flex-col justify-start">
              <div className="flex">
                <div className="mr-1 text-gray-500">Flow meter:</div>{fertigationPump.flowMeter.name}
              </div>

              <div className="flex">
                <div className="mr-1 text-gray-500">Total:</div>{fertigationFlowTotal || 'Unknown'}
              </div>
            </div>
          )}
        </div>

        <div className="flex grow flex-wrap items-center justify-evenly gap-4">
          {get(fertigationPump, 'flowMeter.type') === 'analogFlowMeter' && (
            <div className="mb-12 w-36 translate-y-10">
              <CircularProgressbarWithChildren
                value={fertigationFlowRatePercentage ? fertigationFlowRatePercentage : 0}
                strokeWidth="10"
                circleRatio="0.5"
                styles={buildStyles({
                  rotation: -0.25,
                  pathColor: fertigationPumpStatusColour.pathColor,
                  trailColor: fertigationPumpStatusColour.trailColor,
                })}
              >
                <div className="text-xs text-gray-500">Flow</div>
                <div className="text-xl font-medium">
                  {fertigationFlowRate ? round(fertigationFlowRate, 2) : '-'} gpm
                </div>
              </CircularProgressbarWithChildren>
            </div>
          )}

          {get(fertigationPump, 'flowMeter.type') !== 'analogFlowMeter' && (
            <div className="mb-12 w-36 translate-y-10 text-center">
              <div className="text-xs text-gray-500">Flow</div>
              <div className="text-xl font-medium">
                {fertigationFlowRate ? round(fertigationFlowRate, 2) : '-'} gpm
              </div>
            </div>
          )}

          <div className={classNames('text-center', {
            'w-40': !fertigationPump.flowMeter,
            'min-w-28 w-1/3': fertigationPump.flowMeter,
          })}>
            <HorizontalProgressBar
              className="h-3 min-w-28"
              colorName={fertigationPumpStatus !== 'none' ? `status-${replace(fertigationPumpStatus, '-', '')}` : 'status-stopped'}
              percent={fertigationElapsedPercentage ? fertigationElapsedPercentage : 0}
            />

            {fertigationPump.fertigationBasis === 'time' && (
              <>
                <div className="mt-3 text-xs text-gray-500">
                  {timerOutOfSyncWarning && (
                    <Tippy
                      theme="light"
                      placement="auto"
                      trigger="click"
                      interactive={true}
                      content={
                        <>
                          Stop command not received.
                          Timers may be outdated.
                          Click <button onClick={() => {
                            window.location.reload()
                          }} className="text-status-queued underline">here</button> to refresh the page.
                          If the issue persists, contact support.
                        </>
                      }
                    >
                      <i className="fa fa-exclamation-triangle mr-1 text-red-500 hover:cursor-pointer"></i>
                    </Tippy>
                  )}

                  Remaining
                </div>
                <div className="text-xl font-medium">
                  {fertigationRemaining}
                </div>
              </>
            )}

            {fertigationPump.fertigationBasis === 'quantity' && (
              <>
                <div className="mt-3 text-xs text-gray-500">Remaining</div>
                <div className="text-xl font-medium">
                  {round(fertigationPump.fertigationQuantity - fertigationFlowTotal, 2) || '-'} {fertigationPump.fertigationQuantityUnitOfMeasurement}
                </div>
              </>
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}
