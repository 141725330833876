import { get } from 'lodash-es'
import moment from 'moment'
import { useCallback, useEffect, useRef } from 'react'

export default function useTimer({
  identifyingKey,
  identifyingId,
  displayed,
  setSecondsRemaining,
  status = '',
  setTimerOutOfSyncWarning,
}) {
  let intervalId = useRef(null)
  let noStopInterval = useRef(null)

  const clearTimer = useCallback(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current)
      intervalId.current = null
    }
  }, [])

  const calculateRuntime = useCallback((options) => {
    const timing = options.displayed.timing

    if (!timing) {
      return false
    }

    const remainingRuntime = timing.totalSeconds - timing.elapsedSeconds

    return remainingRuntime
  }, [])

  const createTimer = useCallback((options) => {
    let remainingRuntimeTimer = calculateRuntime(options)
    options.set(remainingRuntimeTimer)

    if (remainingRuntimeTimer) {
      if (intervalId.current) {
        clearTimer()
      }

      intervalId.current = setInterval(() => {
        // Ensure the interval does not run infinitely
        if (remainingRuntimeTimer <= 0) {
          clearTimer()
          noStopInterval.current = setTimeout(() => {
            setTimerOutOfSyncWarning(true)
          }, 10000)
          return
        }

        remainingRuntimeTimer -= 1
        options.set(remainingRuntimeTimer)
      }, 1000)
    }

    return false
  }, [])

  useEffect(() => {
    return () => {
      clearTimer()

      if (noStopInterval.current) {
        clearTimeout(noStopInterval.current)
      }
    }
  }, [])

  useEffect(() => {
    if (status !== 'running' && status !== 'paused') {
      const fullRuntime = moment.duration(displayed.runtime).asSeconds()
      setSecondsRemaining(fullRuntime)

      if (noStopInterval.current) {
        clearTimeout(noStopInterval.current)
        noStopInterval.current = null
      }

      setTimerOutOfSyncWarning(false)
    }

    if (status !== 'running') {
      clearTimer()
    }

    if (status === 'paused') {
      const remainingRuntimeTimer = calculateRuntime({ displayed: displayed })

      setSecondsRemaining(remainingRuntimeTimer)
    }

    if (status === 'running' && get(displayed, 'timing.shouldTick', false)) {
      createTimer({
        identifyingKey: identifyingKey,
        identifyingId: identifyingId,
        displayed: displayed,
        set: setSecondsRemaining,
      })
    }
  }, [
    identifyingKey,
    identifyingId,
    get(displayed, 'timing'),
    setSecondsRemaining,
    status,
    intervalId,
  ])
}
