import * as Sentry from '@sentry/react'
import { clamp, find, get, isEmpty, round } from 'lodash-es'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar'

import Card from '@/Components/common/Card'
import { formatKeys } from '@/Utilities/Form/Formatter'
import { formatDecimals } from '@/Utilities/Internationalization'
import { abbreviationMap } from '@/Utilities/Units'
import useApiClient from '@/Utilities/useApiClient'

function MainLineCard(props) {
  const [mainLineStats, setMainLineStats] = useState([])
  const [statsLoading, setStatsLoading] = useState(true)
  const apiClient = useApiClient()

  const {
    displayedProgram,
    programStatusColour,
  } = props

  const getMainLineRelativeTotalStats = useCallback(async (signal) => {
    const mainLineId = get(displayedProgram, 'mainLine.id', false)

    if (mainLineId) {
      try {
        setStatsLoading(true)
        let { data } = await apiClient.get(`/main-line/relative-stats/${mainLineId}`, { signal: signal })

        if (data && data.success) {
          data = formatKeys({ ...data }, 'camel')
          setMainLineStats(data.data)
        }
      } catch (error) {
        // Ignore abort errors to prevent logging cancellations as exceptions.
        if (error.name !== 'AbortError') {
          Sentry.captureException(error)
        }
      } finally {
        setStatsLoading(false)
      }
    }
  }, [displayedProgram])

  useEffect(() => {
    if (!isEmpty(displayedProgram)) {
      // Create a new AbortController for each effect run.
      const controller = new AbortController()
      getMainLineRelativeTotalStats(controller.signal)

      return () => {
        // Cleanup function cancels the request if displayedProgram changes before completion.
        controller.abort()
        setMainLineStats([])
      }
    }
  }, [displayedProgram])

  const getMainLineUnitOfMeasurement = (program) => {
    if (get(program, 'expectedFlowUnitOfMeasurement')) {
      return abbreviationMap[program.expectedFlowUnitOfMeasurement]
    } else if (get(program, 'mainLine.flowMeter.details.scaleUnitOfMeasurement')) {
      return abbreviationMap[program.mainLine.flowMeter.details.scaleUnitOfMeasurement]
    }

    return ''
  }

  const [
    mainLineFlowWeekTotal,
    mainLineFlowMonthTotal,
    mainLineFlowOverallTotal,
  ] = useMemo(() => {
    const mainLineFlowWeekTotal = get(mainLineStats, 'relativeStats.weekTotal', 0) / 1000
    const mainLineFlowMonthTotal = get(mainLineStats, 'relativeStats.monthTotal', 0) / 1000
    const mainLineFlowOverallTotal = get(mainLineStats, 'relativeStats.total', 0) / 1000

    return [
      mainLineFlowWeekTotal,
      mainLineFlowMonthTotal,
      mainLineFlowOverallTotal,
    ]
  }, [mainLineStats])

  const [
    mainLineUnitOfMeasure,
    mainLineFlowRate,
    mainLineProgramFlowTotal,
    mainLineFlowRatePercentage,
    mainLineLastUpdated,
  ] = useMemo(() => {
    const mainLineFlowStat = find(get(mainLineStats, 'stats', []), { key: 'flow' })
    const mainLineTotalStat = find(get(mainLineStats, 'stats', []), { key: 'total' })
    const mainLineProgramFlowTotal = get(mainLineTotalStat, 'value', 0) / 1000
    const mainLineUnitOfMeasure = getMainLineUnitOfMeasurement(displayedProgram)

    let mainLineFlowRate = mainLineFlowStat?.value
    let mainLineFlowRatePercentage = 0

    if (displayedProgram.expectedFlow && mainLineFlowRate) {
      mainLineFlowRatePercentage = clamp(mainLineFlowRate / displayedProgram.expectedFlow * 100, 0, 100)
    }

    const getLatestUpdatedTime = (stat1, stat2) => {
      let latestTime = null
      let time1 = get(stat1, 'updatedAt', false)
      let time2 = get(stat2, 'updatedAt', false)

      if (time1 && time2) {
        time1 = moment(time1)
        time2 = moment(time2)
        latestTime = moment.max(time1, time2).fromNow()
      }

      return latestTime
    }

    const mainLineLastUpdated = getLatestUpdatedTime(mainLineFlowStat, mainLineTotalStat)

    return [
      mainLineUnitOfMeasure,
      round(mainLineFlowRate, 2),
      round(mainLineProgramFlowTotal, 2),
      mainLineFlowRatePercentage,
      mainLineLastUpdated,
    ]
  }, [displayedProgram, mainLineStats])

  return (
    <Card>
      <Card.Header
        title="Main line"
        className="grid grid-flow-row grid-cols-1 items-center gap-2 md:grid-flow-col md:gap-4"
      >
      </Card.Header>

      <Card.Body className="my-auto overflow-x-hidden p-3.5">
        <div className="@container">
          {statsLoading ? (
            <div className="primary-loader"></div>
          ) : (
            <div className="grid grid-cols-2-card-info justify-start gap-1 text-sm @xs:grid-cols-4-card-info">
              <div className="text-gray-500">Program:</div>
              <div>
                <span>{formatDecimals(mainLineProgramFlowTotal) || 'Unknown'}</span>
                {get(displayedProgram, 'mainLine.flowMeter.details.deltaUnitOfMeasurement') && (
                  <span className="ml-1 text-xs text-slate-500">
                    {get(displayedProgram, 'mainLine.flowMeter.details.deltaUnitOfMeasurement') === 'gal' ? `${abbreviationMap['kgal']}` : `${abbreviationMap['kl']}`}
                  </span>
                )}
              </div>

              <div className="text-gray-500">Week:</div>
              <div>
                <span>{formatDecimals(mainLineFlowWeekTotal)}</span>
                {get(displayedProgram, 'mainLine.flowMeter.details.deltaUnitOfMeasurement') && (
                  <span className="ml-1 text-xs text-slate-500">
                    {get(displayedProgram, 'mainLine.flowMeter.details.deltaUnitOfMeasurement') === 'gal' ? `${abbreviationMap['kgal']}` : `${abbreviationMap['kl']}`}
                  </span>
                )}
              </div>

              <div className="text-gray-500">Month:</div>
              <div>
                <span>{formatDecimals(mainLineFlowMonthTotal)}</span>
                {get(displayedProgram, 'mainLine.flowMeter.details.deltaUnitOfMeasurement') && (
                  <span className="ml-1 text-xs text-slate-500">
                    {get(displayedProgram, 'mainLine.flowMeter.details.deltaUnitOfMeasurement') === 'gal' ? `${abbreviationMap['kgal']}` : `${abbreviationMap['kl']}`}
                  </span>
                )}
              </div>

              <div className="text-gray-500">Total:</div>
              <div>
                <span>{formatDecimals(mainLineFlowOverallTotal) || 'Unknown'}</span>
                {get(displayedProgram, 'mainLine.flowMeter.details.deltaUnitOfMeasurement') && (
                  <span className="ml-1 text-xs text-slate-500">
                    {get(displayedProgram, 'mainLine.flowMeter.details.deltaUnitOfMeasurement') === 'gal' ? `${abbreviationMap['kgal']}` : `${abbreviationMap['kl']}`}
                  </span>
                )}
              </div>
            </div>
          )}

          {(get(displayedProgram, 'mainLine.flowMeter') && displayedProgram.expectedFlow) && (
            <div className="flex justify-center">
              <div className="mb-12 w-40 translate-y-12">
                <CircularProgressbarWithChildren
                  value={mainLineFlowRatePercentage ? mainLineFlowRatePercentage : 0}
                  strokeWidth="10"
                  circleRatio="0.5"
                  styles={buildStyles({
                    rotation: -0.25,
                    pathColor: programStatusColour.pathColor,
                    trailColor: programStatusColour.trailColor,
                    hidden: true,
                  })}
                >
                  <div className="text-xs text-gray-500">Flow</div>
                  <div className="text-xl font-medium">
                    {mainLineFlowRate ? mainLineFlowRate : '-'} {mainLineUnitOfMeasure}
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          )}

          {(!get(displayedProgram, 'mainLine.flowMeter') || !displayedProgram.expectedFlow) && (
            <div className="-mt-7 flex h-full min-h-44 flex-col items-center justify-center">
              <div className="text-xs text-gray-500">Flow</div>
              <div className="text-xl font-medium">
                {mainLineFlowRate ? mainLineFlowRate : '-'} {mainLineUnitOfMeasure}
              </div>
            </div>
          )}

          {mainLineLastUpdated && (
            <div className="flex text-xs text-gray-400">
                Updated {mainLineLastUpdated}
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  )
}

export default MainLineCard
