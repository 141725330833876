import { debounce, each,
  flatMap,
  includes,
  isEmpty,
  isFunction,
  map,
  reject } from 'lodash-es'
import { useCallback, useEffect, useRef, useState } from 'react'

import { subscribe, unsubscribe } from '@/Utilities/Events'
import useToast from '@/Utilities/useToast'

let activeAlertSubscriptions = []

/**
 * `useEventSubscriber` is a custom hook that handles model event subscriptions.
 *
 * @param {string[]} subscribeModels - The models to which we want to subscribe.
 * @param {string[]} subscribeActions - The actions for which we want to listen.
 * @param {function} callback - A callback function to be executed when an event is received.
 *
 * Note: Currently, `subscribeModels` and `subscribeActions` are expected to be hardcoded.
 * The useEffect within this hook uses an empty dependency array to prevent unnecessary re-renders.
 * If the nature of `subscribeModels` or `subscribeActions` changes in the future (i.e., if they
 * become dynamic), the dependency array of the useEffect should be re-evaluated.
 */
export default function useEventSubscriber(subscribeModels, subscribeActions, callback) {
  const { showToast } = useToast()
  const [subscriberModelMap, setSubscriberModelMap] = useState({})
  const staticSubscriberModelMap = useRef(subscriberModelMap)
  const callbackRef = useRef(callback)

  useEffect(() => {
    staticSubscriberModelMap.current = subscriberModelMap
  }, [subscriberModelMap])

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  // Handle subscription
  const handleUpdate = useCallback(({ detail }) => {
    const {
      id,
      model,
    } = detail

    const applicableIds = staticSubscriberModelMap.current?.[model] || []

    // If model IDs have been provided, make sure this event is one of them
    if (!isEmpty(applicableIds) && !includes(applicableIds, id)) {
      return false
    }

    showToast(detail)

    if (isFunction(callbackRef.current)) {
      debounce(callbackRef.current, 500, { maxWait: 2000 })(detail)
    }
  }, [])

  // Remove subscriptions
  const removeSubscriptions = useCallback(() => {
    let updatedActiveAlertSubscriptions = activeAlertSubscriptions

    each(updatedActiveAlertSubscriptions, (eventListener) => {
      updatedActiveAlertSubscriptions = reject(updatedActiveAlertSubscriptions, (subscription) => {
        return subscription == eventListener
      })

      unsubscribe(eventListener, handleUpdate)
    })

    activeAlertSubscriptions = updatedActiveAlertSubscriptions
  }, [])

  // Create subscriptions
  const addSubscriptions = useCallback((subscribeModels, subscribeActions) => {
    const eventListeners = flatMap(subscribeModels, (subscribeModel) => {
      return map(subscribeActions, (subscribeAction) => {
        return `${subscribeModel}.${subscribeAction}`
      })
    })

    let updatedActiveAlertSubscriptions = activeAlertSubscriptions

    each(eventListeners, (eventListener) => {
      updatedActiveAlertSubscriptions = [...updatedActiveAlertSubscriptions, eventListener]

      subscribe(eventListener, handleUpdate)
    })

    activeAlertSubscriptions = [...activeAlertSubscriptions, ...updatedActiveAlertSubscriptions]
  }, [])

  useEffect(() => {
    if (isEmpty(subscribeModels) || isEmpty(subscribeActions)) {
      return
    }

    addSubscriptions(subscribeModels, subscribeActions)

    return () => {
      removeSubscriptions()
    }
  }, [subscriberModelMap])

  return { setSubscriberModelMap }
}
