import { filter, head } from 'lodash-es'

function hexToRgba(hex, opacity) {
  hex = hex.replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

const getStatusColor = (status, key) => {
  const filteredStatus = filter(status, ['key', key])

  if (filteredStatus.length) {
    return {
      pathColor: head(filteredStatus).color,
      trailColor: hexToRgba(head(filteredStatus).color, 0.1),
    }
  }

  return null
}

export const statusColor = (status) => {
  let colorConfig = getStatusColor(status, 'alarms') ||
                    getStatusColor(status, 'failure') ||
                    getStatusColor(status, 'paused') ||
                    getStatusColor(status, 'pre-fert') ||
                    getStatusColor(status, 'post-fert') ||
                    getStatusColor(status, 'running')

  return colorConfig || {
    pathColor: '#647087',
    trailColor: '#f2f4f7',
  }
}
