import { get, isEmpty, map } from 'lodash-es'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'

import ActionIcon from '@/Components/ActionIcon'
import AlarmIcon from '@/Components/alerts/AlarmIcon'
import DropdownList from '@/Components/DropdownList'
import Pill from '@/Components/pill/Pill'
import Table from '@/Components/Table'
import { modalState } from '@/Config/Atoms/General'
import useAuth from '@/Utilities/useAuth'
import usePendingChange from '@/Utilities/usePendingChange'

const Dot = styled.div`
  background-color: ${(props) => {
    return props.color
  }};
  border-radius: 100%;
  display: inline-block;
  margin-right: 5px;
  height: 6px;
  width: 6px;
`

export default function ProgramsSection(props) {
  const auth = useAuth()
  const { PendingChangeStatusIcon } = usePendingChange()

  const navigate = useNavigate()
  const setModal = useSetRecoilState(modalState)

  const tableColumns = useMemo(
    () => {
      return [
        {
          Header: 'Program name',
          accessor: 'name',
          width: '15%',
        },
        {
          Header: 'Status',
          accessor: 'status',
          width: '10%',
        },
        {
          Header: 'Description',
          accessor: 'description',
          width: '25%',
        },
        {
          Header: 'Runtime',
          accessor: 'runtime',
          width: '10%',
        },
        {
          Header: 'I/Os',
          accessor: 'inputOutputs',
          width: '10%',
        },
        {
          Header: '',
          accessor: 'action',
          width: '5%',
        },
      ]
    }, [],
  )

  let tableData = useMemo(() => {
    let programs = map(props.programs, (program) => {
      return {
        ...program,
        name: (
          <div className="flex items-center">
            <div>
              <PendingChangeStatusIcon model={program} className="mr-4" />
            </div>

            <div>
              {program.name}

              {!isEmpty(program.activeAlarms) && (
                <AlarmIcon type="program" uuid={program.uuid} />
              )}
            </div>
          </div>
        ),
        status: (
          (get(props, 'programSet.disabled') || program.disabled) ? (
            <span className="ml-1">
              <Pill color="#647087">
                Disabled
              </Pill>
            </span>
          ) : (
            !isEmpty(program?.operationalStatuses) ?
              map(program.operationalStatuses, (status) => {
                return <Pill color={status.color} key={status.id}>
                  <Dot color={status.color} /> {status.title}
                </Pill>
              }) : '-'
          )
        ),
        runtime: program.runtime ? program.runtime : '',
        inputOutputs: <div className="h-6 w-6 rounded-full border-2 border-white text-center">{program.inputOutputs?.length ? program.inputOutputs.length : 0}</div>,
        action: auth.can('update-program') ? (
          <div className="text-right">
            <DropdownList
              icon={<ActionIcon />}
              options={[{
                label: 'Edit program',
                onClick: () => {
                  setModal({
                    name: 'program',
                    data: {
                      program: program,
                      isEditing: true,
                      onSave: () => {
                        props.getTableDataStart
                      },
                    },
                  })
                },
              }, {
                label: 'Manage program',
                onClick: () => {
                  navigate(`/program/manage/${program.id}`)
                },
              }]}
            />
          </div>
        ) : '',
      }
    })

    return { data: programs }
  }, [props.programs])

  return (
    <>
      {
        props.programs &&
          <Table
            columns={tableColumns}
            data={tableData}
            hidePagination={true}
            hideTitle={true}
            nested={true}
          />
      }
    </>
  )
}
