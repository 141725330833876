import classNames from 'classnames'
import { cloneDeep, isFunction, isNull, map, toNumber } from 'lodash-es'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { twMerge } from 'tailwind-merge'

function DayPicker(props) {
  const {
    externalRef,
    displayOnly,
    initialSchedule,
    className,
  } = props

  const [schedule, setSchedule] = useState(null)
  const [weekCount] = useState(toNumber(props.indexCount) || 4)
  const [firstLoad, setFirstLoad] = useState(true)

  const currentWeek = useCallback(() => {
    const now = moment()

    // Compute the first Monday of the current year
    let currentYearFirstMonday = moment().startOf('year')
    if (currentYearFirstMonday.isoWeekday() !== 1) {
      currentYearFirstMonday = currentYearFirstMonday.add((8 - currentYearFirstMonday.isoWeekday()), 'days')
    }

    let startCycle

    // For a continuous cycle, if the current date is before the first Monday of this year,
    // we use the previous year’s first Monday as the reference.
    if (now.isBefore(currentYearFirstMonday)) {
      const prevYear = now.year() - 1
      let previousYearFirstMonday = moment(`${prevYear}-01-01`)
      if (previousYearFirstMonday.isoWeekday() !== 1) {
        previousYearFirstMonday = previousYearFirstMonday.add((8 - previousYearFirstMonday.isoWeekday()), 'days')
      }
      startCycle = previousYearFirstMonday
    } else {
      startCycle = currentYearFirstMonday
    }

    // Calculate how many full weeks have passed since the reference Monday.
    const weeksPassed = now.diff(startCycle, 'weeks')

    // Map to your cycle (e.g. a 4‑week cycle), ensuring the value falls between 1 and weekCount.
    const cycleWeek = (weeksPassed % weekCount) + 1

    return cycleWeek
  }, [weekCount])

  const scheduleDays = useMemo(() => {
    return {
      monday: 'Mo',
      tuesday: 'Tu',
      wednesday: 'We',
      thursday: 'Th',
      friday: 'Fr',
      saturday: 'Sa',
      sunday: 'Su',
    }
  }, [])

  const scheduleSchema = useMemo(() => {
    return {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    }
  }, [])

  const isJsonString = useCallback((schedule) => {
    try {
      JSON.parse(schedule)
    } catch (e) {
      return false
    }

    return true
  }, [])

  useEffect(() => {
    if (isNull(externalRef)) {
      setDefaultSchedule()
    }
  }, [externalRef])

  useEffect(() => {
    if (firstLoad || displayOnly) {
      setDefaultSchedule()
      setFirstLoad(false)
    }
  }, [initialSchedule])

  const setDefaultSchedule = useCallback(() => {
    let defaultSchedule = []

    if (!initialSchedule) {
      for (let index = 1; index <= props.indexCount; index++) {
        defaultSchedule.push(cloneDeep(scheduleSchema))
      }
    }

    if (initialSchedule) {
      isJsonString(initialSchedule) ? setSchedule(JSON.parse(initialSchedule)) : setSchedule(initialSchedule)
    } else {
      setSchedule(defaultSchedule)

      if (isFunction(props.onChange)) {
        props.onChange(defaultSchedule)
      }
    }
  }, [initialSchedule])

  const toggleDay = useCallback((week, day) => {
    if (isFunction(props.onChange)) {
      let newSchedule = cloneDeep(schedule)

      newSchedule[week][day] = !newSchedule[week][day]

      props.onChange(newSchedule, true)

      setSchedule(newSchedule)
    }
  }, [schedule])

  return (
    <div className={twMerge('flex flex-col flex-grow rounded', className)}>
      {map([...Array(weekCount)], (value, index) => {
        return <div key={`week-${index + 1}`} className="mb-1 flex items-center justify-center gap-1">
          <div
            className={twMerge(
              'mr-2 flex aspect-square w-full items-center justify-center rounded-full min-w-6',
              currentWeek() === index + 1 && 'bg-blue-50 text-primary',
            )}
          >
            {index + 1}
          </div>

          {map(scheduleDays, (value, key) => {
            return <div
              className={twMerge(classNames(
                'aspect-square w-full text-center items-center flex rounded-full justify-center text-sm text-gray-500 min-w-6',
                {
                  'hover:bg-inherit': props.displayOnly,
                  'hover:bg-slate-200': !props.displayOnly,
                  'hover:bg-primary text-white': (schedule && schedule[index] && schedule[index][key]),
                },
                {
                  'hover:cursor-default': props.displayOnly,
                  'hover:cursor-pointer': !props.displayOnly,
                },
                { 'bg-primary text-white': schedule && schedule[index] && schedule[index][key] },
              ))}
              onClick={() => {
                toggleDay(index, key)
              }}
              key={`week-${index + 1}-${key}`}
              readOnly={isFunction(props.onChange) ? false : true}
            >
              {value}
            </div>
          })}
        </div>
      })}
    </div>
  )
}

export default DayPicker
