import * as Sentry from '@sentry/react'
import Tippy from '@tippyjs/react'
import classNames from 'classnames'
import { debounce, includes, isEmpty, map, replace, startCase, toLower, truncate } from 'lodash-es'
import { darken, rem } from 'polished'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'

import ActionIcon from '@/Components/ActionIcon'
import AlarmIcon from '@/Components/alerts/AlarmIcon'
import PermissionGuard from '@/Components/auth/PermissionGuard'
import TableHeader from '@/Components/common/table/Header'
import TypeSearch from '@/Components/common/TypeSearch'
import DropdownList from '@/Components/DropdownList'
import { Anchor, RoundedIconButton } from '@/Components/form/Buttons'
import SkeletonTable from '@/Components/SkeletonTable'
import TableBasic from '@/Components/tables/base/Basic'
import { modalState, pageAlertState } from '@/Config/Atoms/General'
import { standardActions, subscribe, unsubscribe } from '@/Utilities/Events'
import { formatKeys } from '@/Utilities/Form/Formatter'
import { iconClasses } from '@/Utilities/Icons/InputOutputIcons'
import { attributesForTippy, extractAttributes } from '@/Utilities/inputOutput'
import { getPaginationMeta } from '@/Utilities/Pagination'
import { abbreviationToText } from '@/Utilities/Units'
import useApiClient from '@/Utilities/useApiClient'
import useAuth from '@/Utilities/useAuth'
import useEventSubscriber from '@/Utilities/useEventSubscriber'

const HoverIndicator = styled.span`
  background-color: transparent;
  color: var(--primary);
  display: inline-block;
  font-size: ${rem(13)};
  text-align: left;

  &:hover {
    color: ${darken(0.2, '#1570ef')};
    cursor: pointer;
  }
`

const DisabledHoverIndicator = styled.span`
  display: inline-block;
  font-size: ${rem(13)};
  text-align: center;
  white-space: nowrap;
`

export default function InputOutputsBySite(props) {
  // Utilities
  const auth = useAuth()
  const navigate = useNavigate()
  const apiClient = useApiClient()

  // Internal component state
  const setAlert = useSetRecoilState(pageAlertState)
  const [lastQuery, setLastQuery] = useState(null)
  const [tableData, setTableData] = useState(null)
  const [tableDataLoading, setTableDataLoading] = useState(true)
  const [tableSearchTerm, setTableSearchTerm] = useState({
    type: null,
    value: null,
  })
  const setModal = useSetRecoilState(modalState)

  // Events
  useEventSubscriber(['inputOutput'], standardActions, () => {
    updateTable()
  })

  const updateTable = useCallback(() => {
    getTableData(getPaginationMeta(lastQuery))
  }, [lastQuery])

  const getTableData = useMemo(() => {
    return debounce(async ({
      pageIndex,
      pageSize,
      filters,
    }) => {
      const currentQuery = {
        pageIndex,
        pageSize,
        filters,
      }

      setLastQuery(currentQuery)

      const query = new URLSearchParams([
        ['page', pageIndex + 1],
        ['pageSize', pageSize],
        ['searchType', filters?.search?.type || ''],
        ['search', filters?.search?.value || ''],
        ['siteIds[]', props.siteId],
        ['with[]', 'site.area'],
        ['with[]', 'profiles'],
        ['with[]', 'remoteUnit'],
        ['with[]', 'activeAlarms'],
      ])

      try {
        const { data } = await apiClient.get(`/input-output/query?${query}`)

        let inputOutputData = map(data.inputOutputs.data, (inputOutput, key) => {
          const attributes = extractAttributes(inputOutput)
          const type = replace(inputOutput.details_type, 'inputOutput.', '')
          let stateChangeOptions = []

          if (includes(toLower(type), 'output')) {
            stateChangeOptions = [{
              label: 'Manually start output',
              disabled: !auth.can('start-input-output'),
              onClick: () => {
                setModal({
                  name: 'warning',
                  data: {
                    endpoint: `/input-output/state/start/${inputOutput.id}`,
                    title: 'Start output',
                    content: 'This will start the output and require that you manually stop it.',
                    successFlashMessage: `Successfully queued ${inputOutput.name} to be started.`,
                    onComplete: () => {
                      setModal(null)
                    },
                    close: () => {
                      setModal(null)
                    },
                    onFailure: () => {
                      setModal(null)
                      setAlert({
                        type: 'error',
                        content: 'We were unable to process your request. Please try again.',
                      })
                    },
                  },
                })
              },
            }, {
              label: 'Manually stop output',
              disabled: !auth.can('stop-input-output'),
              onClick: () => {
                setModal({
                  name: 'warning',
                  data: {
                    endpoint: `/input-output/state/stop/${inputOutput.id}`,
                    title: 'Stop output',
                    content: 'This will stop the output.',
                    successFlashMessage: `Successfully queued ${inputOutput.name} to be stopped.`,
                    onComplete: () => {
                      setModal(null)
                    },
                    close: () => {
                      setModal(null)
                    },
                    onFailure: () => {
                      setModal(null)
                      setAlert({
                        type: 'error',
                        content: 'We were unable to process your request. Please try again.',
                      })
                    },
                  },
                })
              },
            }]
          }

          return {
            name: (
              <>
                <div className="flex items-center">
                  <Tippy
                    content={(
                      <>
                        <div className="font-bold">
                          {startCase(type)}
                        </div>

                        <div>
                          {`${inputOutput.active ? 'Successfully' : 'Not yet'} activated with the FEP`}
                        </div>
                      </>
                    )}
                    delay={200}
                    theme="light"
                    placement="right"
                  >
                    <div className={classNames({
                      'text-green-600': inputOutput.active,
                      'text-yellow-500': !inputOutput.active,
                    }, 'mr-5 inline-block w-6 cursor-pointer text-center opacity-80')}>
                      <i className={classNames(iconClasses[type], 'text-lg')}></i>
                    </div>
                  </Tippy>

                  <div>
                    <div className="flex items-center">
                      {inputOutput.name}

                      {inputOutput.auxiliary ? (
                        <div className="ml-1 inline-block text-xs text-slate-400">
                        (Auxiliary)
                        </div>
                      ) : null}

                      {!isEmpty(inputOutput.active_alarms) && (
                        <AlarmIcon type="inputOutput" uuid={inputOutput.uuid} />
                      )}
                    </div>

                    {inputOutput.description ? (
                      <Tippy content={inputOutput.description} delay={200} theme="light" disabled={inputOutput.description.length < 35} placement="right">
                        <div className="inline-block cursor-pointer text-xs text-slate-400">
                          {truncate(inputOutput.description, { length: 35 })}
                        </div>
                      </Tippy>
                    ) : null}
                  </div>
                </div>
              </>
            ),
            module: (
              <>
                {inputOutput.module_slot && inputOutput.channel ? (
                  <div>
                  Slot: {inputOutput.module_slot} <br />
                  Channel: {inputOutput.channel}
                  </div>
                ) : (
                  <div>N/A</div>
                )}
              </>
            ),
            info: (
              <>
                <div className="mb-1">
                  {inputOutput.logging ? (
                    <Tippy
                      theme="light"
                      content={
                        <div className="-mb-1 text-slate-500">
                          {inputOutput.logging_type === 'interval' && (
                            <>
                              <div className="mb-1">
                                <strong>Logging Interval:</strong> <br />
                                {inputOutput.logging_interval}
                              </div>
                            </>
                          )}

                          {inputOutput.logging_type === 'time' && (
                            <div className="mb-1">
                              <strong>Logging Scheduled Time:</strong> <br />
                              {inputOutput.logging_scheduled_time}
                            </div>
                          )}

                          {inputOutput.logging_type === 'delta' && (
                            <>
                              <div className="mb-1">
                                <strong>Logging Delta:</strong> <br />
                                {inputOutput.logging_delta}
                              </div>

                              <div className="mb-1">
                                <strong>Logging Delta Unit of Measurement:</strong> <br />
                                {abbreviationToText(inputOutput.logging_delta_unit_of_measurement)}
                              </div>
                            </>
                          )}
                        </div>
                      }
                    >
                      <HoverIndicator>
                        <i className="fa-regular fa-file-lines w-4 text-center"></i>

                        <span className="ml-2 inline-block">
                        Logging
                        </span>
                      </HoverIndicator>
                    </Tippy>
                  ) : (
                    <DisabledHoverIndicator>
                      <i className="fa-regular fa-file-lines w-4 text-center"></i>

                      <span className="ml-2 inline-block">
                      Logging Disabled
                      </span>
                    </DisabledHoverIndicator>
                  )}
                </div>

                <div>
                  {!isEmpty(attributes) && (
                    <Tippy
                      theme="light"
                      key={key}
                      content={
                        <div className="-mb-1 text-slate-500">
                          {attributesForTippy(attributes)}
                        </div>
                      }
                    >
                      <HoverIndicator>
                        <i className="fa-regular fa-sliders w-4 text-center"></i>

                        <span className="ml-2 inline-block">
                          Attributes
                        </span>
                      </HoverIndicator>
                    </Tippy>
                  )}
                </div>
              </>
            ),
            site: inputOutput.site.name,
            area: inputOutput.site.area.name,
            changeOfState: inputOutput.change_of_state ? 'True' : 'False',
            action: (
              <PermissionGuard anyOf={[
                'view-input-output',
                'update-input-output',
                'delete-input-output',
                'start-input-output',
                'stop-input-output',
              ]} auth={auth}>
                <DropdownList
                  icon={<ActionIcon />}
                  options={[
                    ...stateChangeOptions,
                    {
                      label: 'Edit I/O',
                      disabled: !auth.can('update-input-output'),
                      topLine: true,
                      onClick: () => {
                        setModal({
                          name: 'io',
                          data: {
                            io: formatKeys(inputOutput, 'camel'),
                            site: inputOutput.site,
                            isEditing: true,
                            onSave: () => {
                              getTableDataStart(currentQuery)
                            },
                          },
                        })
                      },
                    },
                    {
                      label: 'Manage I/O',
                      disabled: !auth.can('view-input-output'),
                      onClick: () => {
                        navigate(`/io/manage/${inputOutput.id}`)
                      },
                    },
                    {
                      label: 'Delete I/O',
                      topLine: true,
                      disabled: !auth.can('delete-input-output'),
                      onClick: () => {
                        setModal({
                          name: 'warning',
                          data: {
                            endpoint: `/input-output/delete/${inputOutput.id}`,
                            title: 'Delete I/O',
                            content: `Are you sure you want to delete ${inputOutput.name}? This action cannot be undone.`,
                            successFlashMessage: 'I/O deleted successfully.',
                            onComplete: () => {
                              getTableDataStart(currentQuery)
                            },
                            savePreventionState: {
                              model: 'inputOutput',
                              id: inputOutput.id,
                            },
                          },
                        })
                      },
                    },
                  ]}
                />
              </PermissionGuard>
            ),
          }
        })

        let tableData = {
          ...data.inputOutputs,
          data: inputOutputData,
        }

        setTableData(tableData)
        setTableDataLoading(false)
      } catch (error) {
        Sentry.captureException(error)
      }
    }, 250)
  }, [
    setTableData,
    setTableDataLoading,
    navigate,
    setModal,
  ])

  useEffect(() => {
    getTableData({
      pageSize: 15,
      pageIndex: 0,
    })
  }, [getTableData])

  const getTableDataStart = useCallback((params) => {
    setTableDataLoading(true)
    getTableData(getPaginationMeta(params))
  }, [getTableData, setTableDataLoading])

  const tableColumns = useMemo(
    () => {
      return [
        {
          Header: 'Name',
          accessor: 'name',
          width: '29%',
        },
        {
          Header: 'Site',
          accessor: 'site',
          width: '17%',
        },
        {
          Header: 'Area',
          accessor: 'area',
          width: '17%',
        },
        {
          Header: 'Module',
          accessor: 'module',
          width: '15%',
        },
        {
          Header: 'Info',
          accessor: 'info',
          width: '17%',
        },
        {
          Header: '',
          accessor: 'action',
          width: '5%',
          style: { textAlign: 'right' },
        },
      ]
    }, [],
  )

  const searchTypes = useMemo(() => {
    return [
      {
        value: 'input-output',
        label: 'I/O',
      },
      {
        value: 'site',
        label: 'Site',
      },
      {
        value: 'area',
        label: 'Area',
      },
    ]
  }, [])

  const showIoModal = () => {
    setModal({
      name: 'io',
      data: {
        site: props.site,
        onSave: (inputOutput, mapLinkingEnabled) => {
          if (mapLinkingEnabled) {
            navigate(`/io/manage/${inputOutput.id}/coordinates`)
          }
        },
      },
    })
  }

  useEffect(() => {
    const inputOutputCreated = () => {
      getTableDataStart(lastQuery)
    }

    if (lastQuery) {
      subscribe('inputOutputCreated', inputOutputCreated)
    }

    return () => {
      unsubscribe('inputOutputCreated', inputOutputCreated)
    }
  }, [lastQuery])

  return (
    <>
      {
        tableData ?
          <TableBasic
            testId="tableIosBySite"
            columns={tableColumns}
            data={tableData}
            getTableData={getTableDataStart}
            header={
              <TableHeader
                testId="tableIosBySiteTableHeader"
                className="grid-cols-2-right-auto"
              >
                <TableHeader.Title>I/Os</TableHeader.Title>

                <TableHeader.Toolbar>
                  <div className="hidden grid-cols-2-right-auto gap-4 md:grid">
                    <TypeSearch
                      types={searchTypes}
                      search={tableSearchTerm}
                      onChange={(searchTerm) => {
                        return setTableSearchTerm(searchTerm)
                      }}
                    />

                    <PermissionGuard permission="create-input-output" auth={auth}>
                      <Anchor
                        style={{ minWidth: '200px' }}
                        onClick={showIoModal}
                      >
                        Add I/O
                      </Anchor>
                    </PermissionGuard>
                  </div>
                  <PermissionGuard permission="create-input-output" auth={auth}>
                    <div className="block md:hidden">
                      <RoundedIconButton
                        icon="plus"
                        onClick={showIoModal}
                      />
                    </div>
                  </PermissionGuard>
                </TableHeader.Toolbar>

                <TableHeader.SubHeader className="flex flex-col justify-between gap-3 @lg:flex-row @lg:items-center md:hidden">
                  <TypeSearch
                    types={searchTypes}
                    search={tableSearchTerm}
                    onChange={(searchTerm) => {
                      return setTableSearchTerm(searchTerm)
                    }}
                  />
                </TableHeader.SubHeader>
              </TableHeader>
            }
            loading={tableDataLoading}
            searchTerm={tableSearchTerm}
          /> : <SkeletonTable/>
      }
    </>
  )
}
